// Design Slider
.carousel, .slide {
	@include media-breakpoint-up(lg) {					
		height: 80vh;
	}
}

.slide {
  position: relative;
  .imagen {
	height: 80vh;
	background-size: cover;
    width: 100%;
    overflow: hidden;
	opacity: 1 !important;
	background-position: center;
    img {
      max-width: 100%;
      height: auto;
      opacity: 1 !important;
      animation-duration: 3s;
      transition: all 1s ease;
    }
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	background-color: rgba(0,0,0,0.3);
	padding: 2rem;
	@include media-breakpoint-down(md) {					
		width: 100%;
	}
    .headings {
		text-align: center;
		color: #FFF;
		.animated {
			transition: all .5s ease;
		}
		.abrir_noticia{
			text-align: right;
			margin-top: 1rem !important;
			a {
				@extend %leer_mas;
			}
		}
		*{
			padding: 0 !important;
			margin: 0 !important;
		}
    }
  }
}

// Change animation presets
.carousel [data-animation-in] {
    opacity: 0;
    animation-duration: 1.5s;
    transition: opacity 0.5s ease 0.3s;
}

// Dots
.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            display: block;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            transition: all .3s ease;
            &:hover, &:focus {
                outline: none;
            }
        }
    }
}

.simple-dots {
  .slick-dots {
    li {
      width: $slick-dot-width;
      height: $slick-dot-height;
      button {
        border-radius: 50%;
        background-color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        width: $slick-dot-width;
        height: $slick-dot-height;
        &:hover, &:focus {
          opacity: $slick-opacity-on-hover;
        }
      }
      &.slick-active button {
        color: $slick-dot-color-active;
        opacity: $slick-opacity-default;
      }
    }
  }
}

.stick-dots {
  .slick-dots {
    li {
      height: 3px;
      width: 50px;
      button {
        position: relative;
        background-color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        width: 50px;
        height: 3px;
        padding: 0;
        &:hover, &:focus {
          opacity: $slick-opacity-on-hover;
        }
      }
      &.slick-active button {
        color: $slick-dot-color-active;
        opacity: $slick-opacity-default;
        &:hover, &:focus {
          opacity: $slick-opacity-on-hover;
        }
      }
    }
  }
}

/* /////////// IMAGE ZOOM /////////// */
@keyframes zoomInImage {
  from {
    transform: scale3d(1, 1, 1);
  }
  to {
    transform: scale3d(1.1, 1.1, 1.1);
  }
}

.zoomInImage {
  animation-name: zoomInImage;
}

@keyframes zoomOutImage {
  from {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.zoomOutImage {
  animation-name: zoomOutImage;
}

.slick-prev, .slick-next {
    width: 5rem !important;
    height: 5rem !important;
	z-index: $zindex-sticky - 1;	
	@include media-breakpoint-down(md) {					
		width: 2rem !important;
	}
}

.slick-arrow {
    overflow: hidden;
    text-indent: -9999px;
    background-color: #000 !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
    opacity: .9;
    transition: all .25s ease;
    font-size: 0;
    //este grupo es lo necesario para que se centre el resto es personalizacion
    line-height: 0;
    position: absolute;
    top: 40%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    color: transparent;
    border: none;
    outline: none;
    background: transparent;

    &:hover {
        opacity: 1;
        background-size: 2rem;
    }

    &.slick-next {
        right: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjx0aXRsZT5jaGV2cm9uLXJpZ2h0PC90aXRsZT48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTUuNzA2IDExLjI5NGwtNi02Yy0wLjM4Ny0wLjM4Ny0xLjAyNS0wLjM4Ny0xLjQxMyAwcy0wLjM4NyAxLjAyNSAwIDEuNDEzbDUuMjk0IDUuMjk0LTUuMjk0IDUuMjk0Yy0wLjM4NyAwLjM4Ny0wLjM4NyAxLjAyNSAwIDEuNDEzIDAuMTk0IDAuMTk0IDAuNDUgMC4yOTQgMC43MDYgMC4yOTRzMC41MTMtMC4xIDAuNzA2LTAuMjk0bDYtNmMwLjM5NC0wLjM4NyAwLjM5NC0xLjAyNSAwLTEuNDEzeiI+PC9wYXRoPjwvc3ZnPg==);
        outline: 0;

        &:hover {
            right: -5px;
        }
    }

    &.slick-prev {
        left: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjx0aXRsZT5jaGV2cm9uLWxlZnQ8L3RpdGxlPjxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik0xMC40MTMgMTJsNS4yOTQtNS4yOTRjMC4zODctMC4zODcgMC4zODctMS4wMjUgMC0xLjQxM3MtMS4wMjUtMC4zODctMS40MTMgMGwtNiA2Yy0wLjM4NyAwLjM4Ny0wLjM4NyAxLjAyNSAwIDEuNDEzbDYgNmMwLjE5NCAwLjE5NCAwLjQ1IDAuMjk0IDAuNzA2IDAuMjk0czAuNTEzLTAuMSAwLjcwNi0wLjI5NGMwLjM4Ny0wLjM4NyAwLjM4Ny0xLjAyNSAwLTEuNDEzbC01LjI5NC01LjI5NHoiPjwvcGF0aD48L3N2Zz4=);
        outline: 0;

        &:hover {
            left: -5px;
        }
    }
}