.jwplayer {
  position: relative !important;
  padding-bottom: 56.25% !important;
  /* 16:9 Aspect Ratio */
  height: 0 !important;
  width: 100% !important;
  margin: 0 auto !important;

  .jw-media {
    video {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
