@media print {
	body{		
		background-color: transparent !important;
		border: none !important;
	}
    .slider-Fotoprincipal {
        display: none !important; 
    }
    .logout {
        display: none;
    }
    .logoutIcon {
        display: none;
    }
    .pieEnlace {
        display: none;
    } 
    .FotosVideosFicha {
        display: none !important;
    }
    .contenedorIconosTextoHorizontal {
        display: none !important;
    } 
    #sidebar, .compartir, .contenedor_de_video, .rrss, .pasafotos, .leer_mas, #barra_de_busqueda {
        display: none !important;
    }
	.navbar,.navbar-brand{
		display: block;
	}
	.navbar .container > *:not(.navbar-brand){
		display:none !important;
	}
	body > form > header{
		    position: static !important;
	}
	.ultimas_noticias2 {
		margin-top: 0rem !important;
	}
	@page {
		size: auto;
	}
	section:not(.ultimas_noticias2,:first-child),.ultimas_noticias2  article{
        page-break-inside: avoid;
    }
	/*.noticia_full > *:not(header){
		page-break-inside: avoid;
	}*/
	.noticia_full .breakdown .wrapper {
		padding: 0rem;
		padding-top: .5rem;
	}
	body>form>footer{
			background-color: transparent;
			color: black;
		.wrapper {
			background-image: none;
			padding-bottom: 0px;
			.ir_arriba{
				display: none;
			}
		}
	}
	.breakdown {
		background-image: none !important;
		.wrapper {
			background-color: rgba(0,0,0,.1);
		}
		* {
			color: black;
		}
	}
	.patrocinadores,.patrocinadores_interior{
		background-color: transparent !important;
	}
	.agenda{
		.wrapper{		
			li{
				color: darken($fondo_pagina,60)
			}
		}
	}
}
