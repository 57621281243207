[data-balloon] {
    position: relative;
    display: block;
    height: 20%;
    margin-top: 40%;
}

[data-balloon]::before {
    opacity: 0;
    pointer-events: none;
    transition: all .18s ease-out;
    background: rgba(17, 17, 17, 0.9);
    border-radius: 4px;
    color: #fff;
    content: attr(data-balloon);
    font-size: 12px;
    padding: .5em 1em;
    position: absolute;
    white-space: nowrap;
    z-index: 10;
}

[data-balloon]::after {
    background: no-repeat url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"><path fill="rgba(17, 17, 17, 0.9)" transform="rotate(0)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>');
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    opacity: 0;
    pointer-events: none;
    transition: all .18s ease-out;
    content: '';
    position: absolute;
    z-index: 10;
}

[data-balloon]:hover::before, [data-balloon]:hover::after {
    opacity: 1;
    pointer-events: auto;
}

[data-balloon][data-balloon-pos="up"]::before {
    bottom: 100%;
    left: 50%;
    margin-bottom: 11px;
    transform: translate3d(-50%, 10px, 0);
    transform-origin: top;
}

[data-balloon][data-balloon-pos="up"]::after {
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    transform: translate3d(-50%, 10px, 0);
    transform-origin: top;
}

[data-balloon][data-balloon-pos="up"]:hover::before {
    transform: translate3d(-50%, 0, 0);
}

[data-balloon][data-balloon-pos="up"]:hover::after {
    transform: translate3d(-50%, 0, 0);
}

[data-balloon][data-balloon-pos='down']::before {
    left: 50%;
    margin-top: 11px;
    top: 100%;
    transform: translate3d(-50%, -10px, 0);
}

[data-balloon][data-balloon-pos='down']::after {
    background: no-repeat url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"><path fill="rgba(17, 17, 17, 0.9)" transform="rotate(180 18 6)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>');
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    left: 50%;
    margin-top: 5px;
    top: 100%;
    transform: translate3d(-50%, -10px, 0);
}

[data-balloon][data-balloon-pos='down']:hover::before {
    transform: translate3d(-50%, 0, 0);
}

[data-balloon][data-balloon-pos='down']:hover::after {
    transform: translate3d(-50%, 0, 0);
}

[data-balloon][data-balloon-pos='left']::before {
    margin-right: 11px;
    right: 100%;
    top: 50%;
    transform: translate3d(10px, -50%, 0);
}

[data-balloon][data-balloon-pos='left']::after {
    background: no-repeat url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="rgba(17, 17, 17, 0.9)" transform="rotate(-90 18 18)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>');
    background-size: 100% auto;
    width: 6px;
    height: 18px;
    margin-right: 5px;
    right: 100%;
    top: 50%;
    transform: translate3d(10px, -50%, 0);
}

[data-balloon][data-balloon-pos='left']:hover::before {
    transform: translate3d(0, -50%, 0);
}

[data-balloon][data-balloon-pos='left']:hover::after {
    transform: translate3d(0, -50%, 0);
}

[data-balloon][data-balloon-pos='right']::before {
    left: 100%;
    margin-left: 11px;
    top: 50%;
    transform: translate3d(-10px, -50%, 0);
}

[data-balloon][data-balloon-pos='right']::after {
    background: no-repeat url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="rgba(17, 17, 17, 0.9)" transform="rotate(90 6 6)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>');
    background-size: 100% auto;
    width: 6px;
    height: 18px;
    left: 100%;
    margin-left: 5px;
    top: 50%;
    transform: translate3d(-10px, -50%, 0);
}

[data-balloon][data-balloon-pos='right']:hover::before {
    transform: translate3d(0, -50%, 0);
}

[data-balloon][data-balloon-pos='right']:hover::after {
    transform: translate3d(0, -50%, 0);
}

[data-balloon][data-balloon-length='small']::before {
    white-space: normal;
    width: 80px;
}

[data-balloon][data-balloon-length='medium']::before {
    white-space: normal;
    width: 150px;
}

[data-balloon][data-balloon-length='large']::before {
    white-space: normal;
    width: 260px;
}

[data-balloon][data-balloon-length='xlarge']::before {
    white-space: normal;
    width: 380px;
}

@media screen and (max-width: 768px) {
    [data-balloon][data-balloon-length='xlarge']::before {
        white-space: normal;
        width: 90vw;
    }
}

[data-balloon][data-balloon-length='fit']::before {
    white-space: normal;
    width: 100%;
}
