.navbar {
  /* padding: ($grid-gutter-width/2) $grid-gutter-width; */
  background: $white;
  border: none;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  margin-bottom: 0;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

  &,
  .navbar-collapse {
    /* flex-wrap: wrap; */
    @include media-breakpoint-up(lg) {
      justify-content: center;
    }
  }

  .navbar-collapse {
    transition: all cubic-bezier(1, -0.63, 0.14, 1.91) 0.5s;
    flex-wrap: wrap-reverse;
    //padding-top: 55px;
  }

  &.smalleado {
    @include media-breakpoint-down(md) {
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }

    .navbar-collapse {
      //padding-top: 27px;
    }
  }

  .navbar-nav {
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .navbar-brand {
    img {
      @extend %logo_svg_ancho;

      transition: width cubic-bezier(1, -0.63, 0.14, 1.91) 0.5s;
    }

    &.smalleado {
      @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 0;
      }

      img {
        width: 200px;
      }
    }
  }

  .grupo_secundario {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;

    ul:nth-child(n+2) {
      margin-left: 1rem;
    }
  }

  @include media-breakpoint-down(xl) {
    .navbar-collapse {
      justify-content: space-evenly;
    }

    .main-menu {
      flex-basis: 100%;
      justify-content: center;
    }

    .idioma {
      flex-direction: row;
      justify-content: space-around;
    }
  }
}

.navbar-btn {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  outline: none !important;
  border: none;
}

.srrss {
  flex-direction: row;
  justify-content: space-evenly;

  .navbar-form-wrapper {
    margin-left: 2rem;
  }

  @include media-breakpoint-down(md) {
    .navbar-form-wrapper {
      flex-basis: 100%;

      .navbar-form {
        justify-content: space-evenly;
      }
    }
  }
}

.idioma {
  button {
    border-radius: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.busqueda {
  .nav-link:hover {
    cursor: pointer;
  }
}

.search-box {
  position: relative;

  input.form-control {
    padding-right: 35px;
    border-radius: 0;
    padding-left: 0;
    border-width: 0 0 1px 0;
    box-shadow: none;
  }

  input.form-control:focus {
    border-color: $secondary;
  }

  .input-group-addon {
    min-width: 35px;
    border: none;
    background: transparent;
    position: absolute;
    right: 0;
    z-index: 9;
    padding: 7px 0 7px 7px;
    height: 100%;
  }

  i {
    color: $primary;
    font-size: 19px;
  }
}

#barra_de_busqueda {
  display: none;
  background-color: transparentize($white, 0.1);
  -webkit-box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.75);

  &.show {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }
}

@include media-breakpoint-up(lg) {
  .form-inline .input-group {
    width: 250px;
    margin-right: 30px;
  }

  .form-inline {
    display: block;
    margin-top: 0;
  }

  .input-group {
    width: 100%;
  }
}

.oculto {
  display: none;
}

.descripcion {
  color: white;
  text-align: left;
}

.contactoMenu {
  color: white;
  text-align: right;
}

@media (max-width: 1200px) {
  .descripcion {
    display: none;
  }

  .contactoMenu {
    text-align: left;
  }
}

.btnLogin {
    color: white;
    background: $primary;
    border: none;
    padding-left: 6%;
    padding-right: 6%;
    margin-top: 1rem;
}

.login {
  text-align: center;
}

.campoLogin {
  margin: 1%;
  height: 1%;
}

.abonados {
  color: $primary;

  /* font-weight: bold; */
}

.barraSuperior {
  background: $secondary;
  width: 100%;
  height: 25px;
  padding: 2px;
  font-size: 14px;
}
