%leer_mas {
    font-size: 1.2rem;
    font-weight: 500;
    background-color: transparentize($black, .50);
    color: white;
    padding: 0.5rem !important;
    transition: all .2s cubic-bezier(.55,.09,.68,.53);
    margin-right: 30%;
    margin-left: -30%;

    &:first-letter {
        text-transform: uppercase;
    }

    &:hover {
        background-color: black;
        transition: all .2s cubic-bezier(.55,.09,.68,.53);
        padding: 0.6rem !important;
    }
}

%logo_svg_ancho {
	width: 350px;	
	@include media-breakpoint-down(lg) {
		width: 250px;
	}
}



%side_menu_ext_1 {
	color: inherit;
	text-decoration: none;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: all 0.3s;
}