/* example taken from https://stackoverflow.com/a/48976550/3466464 */
/* import what we need to override */
$primary: #ff6319;
$secondary: #353535;
$tertiary: #000;

$fondo_footer:      #1d262d;
$fondo_pagina:      #f4f4f4;
$black:    #000;
$color_sombra:      transparentize($black, .90);
$color_sombra_hover:      transparentize($black, .70);

$body-color: lighten($black,20);
$link-color: black;
$link-hover-color: lighten(black,20);

$relacion_img_background: 4/3;

/*$link-decoration:;
$link-hover-color:;
$link-hover-decoration:;*/


$tamano_articulo: 1.3rem;

// Vars
$slick-dot-width: 20px;
$slick-dot-height: 20px;
$slick-dot-color: white;
$slick-dot-color-active: $slick-dot-color;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;





// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows:                              true;
$enable-gradients:                            true;


/* set the overriding variables */

//MULTIPLOS DE 12 https://github.com/twbs/bootstrap/issues/21333
/*$grid-breakpoints: (  
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1680px
);


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1000px,
  xxxl: 1000px
);*/

/* override the !default vars with the values we set above */