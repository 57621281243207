#sidebar {
	$ancho_logo_cabecera_sidebar: 250px;
	$padding_logo_cabecera_sidebar: 20px;
	width: $ancho_logo_cabecera_sidebar;
	position: fixed;
	top: 0;
	left: -$ancho_logo_cabecera_sidebar;
	height: 100vh;
	z-index: $zindex-fixed +2;
	background: $secondary;
	color: #fff;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
	overflow-y: scroll;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
	.sidebar-header {		
		padding: $padding_logo_cabecera_sidebar;
		background: lighten( $secondary, 10% );
	}
	.navbar-brand{
		img{
			width: $ancho_logo_cabecera_sidebar - $padding_logo_cabecera_sidebar * 2;
		}
	}
	.rrss_mm,.idioma_mm{
		display: flex;
		justify-content: center;
	}
	&.active {
		left: 0;
	}	
	ul {
		&.components {
			padding: 20px 0;
			border-bottom: 1px solid lighten( $secondary, 10% );;
		}
		p {
			color: $white;
			padding: 10px;
		}
		li {
			a {
				padding: 10px;
				font-size: 1.1em;
				display: block;
				&:hover {
					color: $secondary;
					background: $white;
				}
			}
			&.active > a {
				color: $white;
				background: lighten( $secondary, 10% );
			}
		}
		&.CTAs {
			padding: 20px;
			a {
				text-align: center;
				font-size: 0.9em !important;
				display: block;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 5px;
				margin-bottom: 5px;
				background: lighten( $secondary, 10% ) !important;
				&,&:hover,&:focus {
					@extend %side_menu_ext_1;
				}
				&.busqueda_mm {
					background: lighten( $secondary, 10% ) !important;
					color: $white !important;
					&:hover {
						color: lighten( $secondary, 10% ) !important;
						background: $white !important;
					}
				}
				&.colabora_mm {
					background: lighten( $secondary, 10% ) !important;
					color: $white !important;
					&:hover {
						color: lighten( $secondary, 10% ) !important;
						background: $white !important;
					}
				}
			}
		}
		a {
			&,&:hover,&:focus {
				@extend %side_menu_ext_1;
			}
		}
		ul a {
			font-size: 0.9em !important;
			padding-left: 30px !important;
			background: lighten( $secondary, 10% );
		}
	}	
	.dropdown-toggle::after {
		display: block;
		position: absolute;
		top: 50%;
		right: 20px;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-50%);
	}
	a[aria-expanded="true"] {
		color: $white;
		background: lighten( $secondary, 10% );
	}
	a[data-toggle="collapse"] {
		position: relative;
	}

	#dismiss {
		width: 35px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		background: $secondary;
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: all 0.3s;
		&:hover {
			background: $white;
			color: $secondary;
		}
	}
}
.sidebar_overlay {
	visibility: hidden;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.3);
	z-index: $zindex-sticky +1;
	opacity: 0;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: all 0.5s ease-in-out;
	top: 0;
	&.active {
		visibility: visible;
		opacity: 1;
	}
}
