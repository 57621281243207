//fonts
$font_0: 'Noto Serif', serif; /*'Playfair Display', serif;*/ /*merriweather;*/
$font_1: Patua one;


.titulo{
    font-family: $font_1;
}

.ultimas_noticias{
	margin-top: -4rem !important;

	.row{
		margin-bottom: 1rem;
	}
}
.ultimas_noticias2{
	margin-top: -4rem !important;
}
.breakdown{
	/*background-color:$tertiary;
	background-image: url(../img/fondo_web_superior_rojo.svg);*/
	background-image: url(../img/breakdown/1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	> .wrapper {
		background-color: transparentize($black, .50);
		padding: 10rem;
		@include media-breakpoint-down(md) {
			padding: 10rem 1rem;
		}
	}
	*{	
		color: $white;
		text-align: center;
		//font-weight: bold;
		letter-spacing: 0.01rem;
	}
}

.card {
	margin-bottom: 1rem;
	border: 0;	
	/*.card-body {
		padding-top: 0;
	}*/
	.ultimas_noticias & .card-img-top,	.galeria & .card-img-top,	&.horizontal.comprimido .card-img-top,	.img_relacionadas3 & .card-img-top{		
		$principal_col_width: (
		  xs: 6,
		  sm: 6,
		  md: 6,
		  lg: 3,
		  xl: 3,
		  xxl: 3,
		  xxxl: 3
		);
		@include media-breakpoint-only(xs) {
			height:calc((((500px / #{$grid-columns}) * #{map-get($principal_col_width, xs)}) - #{$grid-gutter-width}) / #{$relacion_img_background});
		}
		@each $key,$val in $container-max-widths{
			@include media-breakpoint-only(#{$key}) {
				height:((($val / $grid-columns) * map-get($principal_col_width, #{$key})) - $grid-gutter-width) / $relacion_img_background;
			}
		}		
		width: 100%;
		div{
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			cursor:pointer;
			height: 100%;			
		}
	}
	.img_relacionadas3.expandido & .card-img-top{
		height: auto;
	}
	.ultimas_noticias .n_secundarias & .card-img-top{			
		$secundario_col_width: (
		  xs: 6,
		  sm: 4,
		  md: 3,
		  lg: 2,
		  xl: 2,
		  xxl: 2,
		  xxxl: 2
		);
		@include media-breakpoint-only(xs) {
			height:calc((((500px / #{$grid-columns}) * #{map-get($secundario_col_width, xs)}) - #{$grid-gutter-width}) / #{$relacion_img_background});
		}
		@each $key,$val in $container-max-widths{
			@include media-breakpoint-only(#{$key}) {
				height:((($val / $grid-columns) * map-get($secundario_col_width, #{$key})) - $grid-gutter-width) / $relacion_img_background;
			}
		}
	}
	&.horizontal{
		figure{
			margin-bottom: 0;
		}
		.card-block{
			padding-top: $grid-gutter-width / 2;
			padding-bottom: $grid-gutter-width / 2;
		}
	}
}

body {
	font-family: $font_0;
	background-color: $fondo_pagina;
	/*background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../img/fondo_web.jpg");*/
	> form > footer{
		$valor_espacio_footer: ($grid-gutter-width * 3) / 2;
		margin-top:$valor_espacio_footer;		
		background-color: $fondo_footer;
		border-top: 1px solid $primary;
		color: $white;
		/*background-image: url(../img/dots.svg);
		background-size: cover;*/		
		a{
			color: $tertiary;
			&:hover{
				color: lighten($tertiary,20);
			}
		}
		hr{
			border-top: 1px solid rgba(0,0,0,.3);
		}
		.footer_links{
			list-style: none;
			padding-left: 0;
			li{
				display: inline;
				margin-right: .5rem;
			}
			img{
				max-width:230px;
			}
		}
		.footer_logos{
			display: flex;
			align-items: center;
			justify-content: center;
			.ir_arriba{
				text-align: center;
				svg{
					font-size: 4rem;
				}
				.txt {
					font-size: 1.2rem;
				}
				&:hover{
					cursor: pointer;
					color: lighten($tertiary,20);
				}
			}
		}
		.wrapper{
			padding-top:$valor_espacio_footer;
			padding-bottom: 80px;//para acomodar el fondo sin que pise texto 
			background-image: url(../img/fondo_web_superior_verde.svg);
			background-repeat: no-repeat;
			background-position: bottom right;
		}
	}
	main{
		a{
			/*font-weight: bold;*/
			&:hover{
				text-decoration: none;		
			}
		}
	}
	> form > main > section{		
		//&:not(.pasafotos){
		&:not(:first-of-type){
			margin-top: 6rem;
			margin-bottom: 6rem;
		}
	}
}

.listaTrianguloCerrado{
	list-style: disclosure-closed;
}

section{
	> header{
		padding: 1rem 0;
		padding-left: 0px;
		padding-left: 0px;
		/*margin-bottom: 1rem;*/
		border-radius: 0.31rem;
		/*text-align: center;*/
		/*border-bottom: 2px solid $secondary;*/
		//font-family: $font_1;
	}
	> footer{
		margin-top:.5rem;
		display: flex;
		justify-content: flex-end;
		a + a{
			margin-left: 1rem;
		}
	}
}

.leer_mas{
	@extend %leer_mas;
}
		
p {
	font-family: $font_0;
	line-height: 1.7em;
}
.carousel{
	margin-bottom: ($grid-gutter-width/2);
}
.fa-copia{
	display: inline-block;
	font-size: inherit;
	height: 1.2em;
	overflow: visible;
	//vertical-align: -.125em;
	svg{
		fill:yellow;
	}
}
details{
	&[open] summary ~ * {
		animation-name: fadeInDown;
		animation-duration: 1s;
		animation-fill-mode: both;
	}
	p{
		margin-left: 2rem;
	}
	ul{
		margin-left: 3rem;
	}
}


button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

#columna_1lista_1_cabecera{//ñapa
	display: none;
}


.ultimas_noticias2{
	$min_alto: 350px;
	.n_secundarias{			
		margin-bottom: 1.5rem;
	}
	.n_contenedor{
		.noticia{			
			height: $min_alto;
			background-size: cover;
			background-clip: content-box;
			background-repeat: no-repeat;
			background-position: center;
			&:nth-child(n+2){
				padding-top:1rem;
			}
			&:nth-child(n+1){
				padding-top:1rem;
			}
			a{
				height: 100%;
				display: flex;
				box-shadow: 3px 3px 3px $color_sombra;
				transition: all ease-in-out .2s;
				&:hover{
					text-decoration: none;
					box-shadow: 4px 4px 6px $color_sombra_hover;
					transition: all ease-in-out .2s;
					header{
						background-color: rgba(0,0,0,0.9);
						transition: all ease-in-out .2s;
					}
				}
				header{
					background-color: rgba(0,0,0,0.5);
					display: flex;
					color: $white;
					padding: 1.5rem;
					margin: 1rem;
					flex-direction: column;
					height: max-content;
					align-self: flex-end;
					transition: all ease-in-out .2s;					
					.titulo{
						font-size: 1.5rem;
					}
					.fecha{
						align-self: flex-end;
					}
					@include media-breakpoint-down(lg) {
						padding: 1rem;
						margin: 1rem;
						width: 100%;
						.titulo{
							font-size: 1.2rem;
						}
					}
				}
			}
		}
		&.doble{
			.noticia{
				height: $min_alto * 2;
				@include media-breakpoint-down(md) {
					height: $min_alto;
					&:nth-child(1){
						padding-top:0;
					}
				}
			}				
			
			header{				
				width: 40%;
				@include media-breakpoint-down(md) {
					width: 100%;
				}
			}
		}
		&.n_secundarias{
			.noticia{			
				padding-top: 2rem;
				@include media-breakpoint-down(md) {
					&:nth-child(n+3){
						display: none;
					}
				}
			}
		}
	}
	
}
.galeria, .img_relacionadas3 {
    .card {
        box-shadow: 3px 3px 3px $color_sombra;
        transition: all ease-in-out .2s;
        background: transparentize($secondary, .20);

        &:hover {
            box-shadow: 4px 4px 6px $color_sombra_hover;
            transition: all ease-in-out .2s;
        }
    }

    .card-img-top {
        > div {
            background-position: center;

            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparentize($black, .93);

                svg {
                    font-size: 3rem;
                    color: $white;
                }
            }
        }
    }
}
.row.img_relacionadas3{
	justify-content: center;
}
.periodico{
	
}

	.slick-slide:not(.slick-active) .card{
		opacity: 0.5;		
		transition: opacity .5s ease-in-out, box-shadow .5s ease-in-out;
	}
	.slick-active .card{
		box-shadow: 3px 3px 3px $color_sombra;
		opacity: 1;
		transition: box-shadow .5s ease-in-out, opacity .5s ease-in-out;
		&:hover{
			transition: box-shadow .3s ease-in-out,opacity .3s ease-in-out;
			box-shadow: 4px 4px 6px $color_sombra_hover;
		}
	}
	/*.slick-active ~ .slick-slide .card{
		box-shadow: 3px 3px 3px $color_sombra;
		transition: box-shadow 0.5s ease-in-out;
	}*/
.grid-sizer {
	//width: 33.333%;
}

.contenidoApartado{
	font-size: $tamano_articulo;
}

.noticia_full{
	> :nth-child(2){
		/*margin-top: 2rem;*/
	}
	.compartir{
		display: inline;
		*{
			display: inline;
		}
		ul{
			margin-left: 1rem;
			padding-left: 0;
		}
		li{
			margin:0 .5rem;
			cursor: pointer;
		}
	}
	.noticia_relacionada{
		ul{
			li{
				list-style: disclosure-closed;
				font-style: italic;
			}
		}
	}
	.agenda{
		.wrapper{
			padding: 1em 0;
			padding-right: 1em;
			background: darken($fondo_pagina,10);
			border-radius: 1em;
		}
		ol{
			//display: inline-block;
			font-size: $tamano_articulo;
			color: darken($fondo_pagina,40);			
		}
		li{
			border-bottom: 1px darken($fondo_pagina,20) solid;
			&:hover{
				color: darken($fondo_pagina,50)
			}
		}
		li .tipo + .contenido::before {
			content: ": ";
		}
	}	
	.contenedor_de_video {
		position:relative;
		padding-bottom:56.25%;
		padding-top:0px;
		height:0;
		iframe {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	#map-canvas {
		height: 50vh;
		width: 100%;
	}
	.breakdown{
		background: none;
		.wrapper{
			color: $black;
			background: none;
			padding: 3rem 0rem;
			justify-content: center;
			@include media-breakpoint-down(md) {
				padding: 5rem 0rem;
			}
			hr{
				margin: .5rem;
			}
			h1,h2,h3,h4,h5,h6{
				font-weight: normal;
			}
		}
		*{
			color: transparentize($black,.3);
		}
	}
	.aside_intro{
		margin-bottom: 2rem;
	}
	.img_relacionadas2,.aside_intro{
		:not(.doc_relacionados){
			figure{
				position: relative;
				&:hover{
					figcaption{
						transition: all ease-in-out .2s;
						background: transparentize($black, .10);
					}
				}
				figcaption{
					transition: all ease-in-out .2s;
					font-size: 60%;
					position: absolute;
					/*bottom: 0;*/
					/*left: 0;*/
					box-sizing: border-box;
					width: 100%;
					padding: 1.3em;
					color: $white;
					background: $black;
					background: transparentize($black, .50);
					text-shadow: 1px 1px 0 transparentize($black, .50);
                    display:block;
                    /*height:100%;*/
                    position:relative ;
                    font-size:15px;
				}
			}
		}
	}
	.cuerpo{
		margin-top: 2rem;
		font-size: $tamano_articulo;
	}
	.por{
		font-size: 2rem;
		font-style: italic;
		text-decoration: underline;
		margin-top: 2rem;
		text-align: center;
	}
	.referenciado {
		//margin-bottom: 1rem;
		.row,&.row{
			border-left: 2px solid darken($fondo_pagina,80);
			//margin-left: 1rem;
			//margin-right: 1rem;
			.col-12{
				background-color:  darken($fondo_pagina,10);
			}
			a{
				&:hover{
					color: lighten($black,10);
				}
			}
			ul{
				list-style: none;
				margin-bottom: 0;
				padding-left: 0;
				font-style: italic;
			}
			li{
				display: inline-block;
			}
			li + li::before {
				content: " > ";
			}
		}
	}
	aside{
		> section{
			margin-top: 4rem;
		}
		header{
			padding: 2rem 0;
			padding-left: 0px;
			padding-left: 0px;
			margin-bottom: 1rem;
			border-radius: 0.31rem;
			text-align: center;
			/*border-bottom: 2px solid $secondary;*/
			//font-family: $font_1;
		}
		.img_relacionadas{
			justify-content: center;
			figure{
				height: 100%;
				display: flex;
				a{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		.grid{
			margin: 0 auto;
			figure{
				/*max-width: calc(20% - 10px);
				@include media-breakpoint-down(sm) {
					max-width: 100%;
				}
				@include media-breakpoint-between(sm, lg) {
					max-width: calc(50% - 10px);
				}*/
				margin-bottom: 10px;
				figcaption{
					//display: none;
				}
			}			
		}
		.galeria, .img_relacionadas3{
			justify-content: center;
		}
		.galeria_noticia{
			figure{
				padding:0 0.5rem;
				margin: 0;
				img{
					margin: 0;
				}
			}
			figcaption{
				display: none;
			}
		}
	}
	footer{
		ul{
			//list-style: none;
		}
	}
}
.slider_galeria_background{
	$principal_col_width: (
		  xs: 6,
		  sm: 6,
		  md: 6,
		  lg: 3,
		  xl: 3,
		  xxl: 3,
		  xxxl: 3
		);
		@include media-breakpoint-only(xs) {
			height:calc((((500px / #{$grid-columns}) * #{map-get($principal_col_width, xs)}) - #{$grid-gutter-width}) / #{$relacion_img_background});
		}
		@each $key,$val in $container-max-widths{
			@include media-breakpoint-only(#{$key}) {
				height:((($val / $grid-columns) * map-get($principal_col_width, #{$key})) - $grid-gutter-width) / $relacion_img_background;
			}
		}	
	a{
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
		display: block;
		.descripcion_imagen{
			display: none;
		}
	}
}


.card-columns{
	column-count: 4;
	@include media-breakpoint-down(lg) {
		column-count: 3;
	}
	@include media-breakpoint-down(md) {
		column-count: 2;
	}
	@include media-breakpoint-down(xs) {
		column-count: 1;
	}
}

.formulario{
		background-color: darken($fondo_pagina,10);
		padding-bottom: 2rem;
		svg{
			color: #ff4b4b;
			font-size: .8rem;
		}
	}

.doc_relacionados{
	figure{
		svg{
			font-size: 6rem;
			display: block;
			margin: auto;
			&.fa-file-pdf{
				color:#E2574C;
			}
			&.fa-file-word{
				color:#004e8c;
			}
			&.fa-file-excel{
				color:#0b6a0b;
			}
			&.fa-file-powerpoint{
				color:#ca5010;
			}
			&.fa-file-video{
				color:#c239b3;
			}
			&.fa-file-audio{
				color:#ffaa44;
			}
		}								
	}
	figcaption{
		text-align: center;
	}
}
.patrocinadores{
	display: block;
	background-color: $fondo_pagina;
	.patrocinadores_interior {
		background-color: $fondo_pagina;
		//padding: 60px 20px;
		margin: 0 auto;	  
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
	}
	.patrocinadores_interior > .patrocinadores_item,.patrocinadores_grupo {
		margin: 0 20px;
	}
	.patrocinadores_item {
		/*padding: 5px;
		padding-top: 25px;*/
		position: relative;
	}
	.patrocinadores_item.mostrar_titulo:before,	.patrocinadores_grupo.mostrar_titulo:before {
		font-size: $tamano_articulo;
		text-transform: uppercase;
		content: attr(data-name);
		padding: 0px 5px;
		display: block;
		margin-bottom: 10px;
		color: $fondo_footer;
		line-height: 140%;
		position: absolute;
		top: 0;
		left: 10px;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
	}
	.patrocinadores_imagen {
		display: block;
		height: 200px;
	}
	.patrocinadores_grupo {
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 5px;
		/*padding-top: 5px;
		padding-top: 25px;*/
		position: relative;
		.patrocinadores_item {
			padding: 0 5px;
		}
	}

}
.dropdown-menu {
    font-size:1.1rem;
}
.EnlacePersefone {
    margin: 1rem;
    cursor: pointer;
    transition: all ease-out .1s;

    *:hover {
        transition: all ease-out .1s;
        fill: currentColor;
        color: $secondary !important;
    }
}
.textoComercial1 {
    padding: 2%;
    /*padding-top: 3%;
    padding-bottom: 1%;*/
}

.textoComercial2 {
    /*padding-left: 7%;
    padding-right: 7%;*/
    font-weight: bold;
    font-size: 18px;
    /*padding-top: 10%;*/
    line-height: 28px;
    /*padding-top: 1rem;*/
    padding: 2%;
}

.Texto {
    margin-top: 0.5rem;
    line-height: 1.6rem;
    font-size: 1.2rem;
}
.TextoPortada {
    margin-top: 0.3rem;
    line-height: 1.4rem;
    font-size: 1.2rem;
}

#menu1 {
   font-size:1.2rem;
}

.imagenPrincipal {
    padding-right: 0px !important;
    padding-left: 0px !important;
    /*height: 500px;*/
    object-fit:cover;
}
.fechaNoticia {
    color: $secondary !important;
    /*font-weight: bold;*/
    margin-left:1rem;
}

.fechaNoticiaDetalle {
    color: $primary !important;
    font-weight: bold;
    font-size:1.1rem;
} 
.textoGrande {
    font-size: 45px;
}
.imagenPersonaje{
    max-width:20rem;
}
.contenedorimgPersonaje{
    text-align:center;
}
.cuadroImagen {
    background: $primary;
    padding: 3%;
    /*height: 250px;*/
    margin-top: -250px;
}

.tituloSeccionDefault {
    color: #4e4e4e;
    font-weight: bold;
    font-size: 26px;
    /*margin-left: 4rem;*/
}

.separador {
    border: solid 4px #4e4e4e;
    margin-top: 10rem;
    margin-bottom: 1%;
}

.buscador {
    background: $secondary;
    padding: 0.5rem;
    color: white;
    padding-left: 5rem !important;
    padding-right: 5rem !important;
    padding: 1rem;
    margin-bottom:3rem;
    h2 {
        font-size: 2rem;
    }

    .pd {
        padding: 0.5rem;
    }

    .centrado {
        text-align: center;
    }
}

#textContainer {
    font-size: 1.3rem;
}

.tituloNoticia {
    font-size: 20px;
    color: #444343;
    /*padding: 2%;*/
    padding-left: 1%;
    padding-right: 1%;
    margin-top: 3%;
}
.tituloNoticiaInterno {
    font-size: 20px;
    color: #444343;
    padding: 2%;
    padding-left: 0%;
    padding-right: 4%;
    padding-top: 0;
    line-height:28px;
}

.tituloNoticiaPrivada {
    font-size: 1.5rem;
    color: #2b2b2b;
    margin-bottom: 1%;
    font-weight:bold;
}
.tituloEspecial {
    font-size: 25px;
    color: $primary;
    margin-bottom: 3%;
}
.fechaNoticiaEspecial {
    color: $secondary;
    font-weight:bold;
}
.descripcionGaleria {
    font-size: 1.2rem !important;
    color: $secondary !important;
    PADDING: 1rem !important;
}
.fechaNoticia {
    font-size: 14px;
}
.seccion {
    color: $primary;
    /*font-weight: bold;*/
}
.seccionMod {
    color: #006633;
}
.tituloReportajes {
    color: white;
    margin-left: 10%;
    font-size: 26px;
    margin: 2%;
    width: 100%;
}

.reportajeTexto {
    background: transparent;
    color: white;
    padding: 3%;
    font-size: 22px;
    margin-bottom: 5%;
    &:hover{
        color:white!important;
    }
}

.reportaje {
    font-size: 20px;
    padding-left: 2%;
    padding-right: 2%;
}

.imgReportaje {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.zonaGris {
    background: $secondary;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    padding-top: 1%;
}

.zonanPie {
    background: $primary;
    /*padding-left: 10%;
    padding-right: 10%;*/
    padding-bottom: 2%;
    padding-top: 1%;
}

.contacto {
    font-weight: bold !important;
        a {
        color: $secondary;
    }
}

.icono {
    color: $primary;
}
.iconoContacto {
    width: 5rem !important;
    border-radius: 1000px;
    background: grey;
    height: 5rem !important;
    padding: 1.3rem;
    color: $secondary;
}

.centrado-medio {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /*width: 30%;*/
    text-align: center;
}

.centrado-grande {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /*width: 50%;*/
    text-align: center;
    font-size: 100%;
    /*margin-bottom: 3rem;*/
}

.enImagenes {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0!important ;
}

.imagen {
    width: 100%;
    height: 17rem;
    object-fit:cover;
}
.videoOculto{
    display:none;
}
.video {
    padding: 1.5rem;
    display: block !important;
    background: transparent !important;
    box-shadow: none !important;
    transition: none !important;

    img {
        width: 100%;
        height: 12rem;
        object-fit: cover;
    }

    h6 {
        font-size: 1.4rem;
        text-align: center;
        margin-top: 0.6rem;
    }

    .fechaNoticia {
        font-size: 1rem;
        padding: 0.3rem;
    }

    .icono {
        color: white;
    }
}


.listaClientes {
    list-style: none;
    color:white;
    /*font-weight:bold;*/
}

.enlaceLista {
    color: white;
    &:hover {
    color: black;
    }
}

.card-body {
    background: rgba(53,53,53,.8);

    .contenedorIconosTexto {
        background: transparent;
        box-shadow: none;
        
    }

    svg {
        color: white !important;
    }
}
.iconoImagen {
    width: 40px !important;
    height: 40px !important;
}
.iconoImagenfinal {
    width: 1.3rem !important;
    height: 1.3rem !important;
    margin-top: 0.8rem;
    margin-left: 1rem;
}
.textoBlanco {
    color: white !important;
    padding: 5%;
}
.sinPadding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.sinPadd {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.MargenAuto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.sinMargen {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.noticiasMargen {
    padding-left: 10% !important;
    padding-right: 10% !important;
}
.imgSlider {
    height: 150px;
    object-fit: cover;
    object-position : top;
    cursor: pointer ;
}
.slick-track {
     margin-left: 0!important; 
     margin-right: 0!important; 
}
.autorCursiva {
    font-style: italic;
}
.imgSliderGrande {
    height: 160px;
    object-fit: cover;
    object-position: top;
}
.tituloSlider {
    font-size: 15px;
    color: white;
}
.tituloNoticias {
    color: $primary;
    font-size:25px;
    margin:2%;
}
.tituloSeccion {
    border-top: solid 4px;
    border-color: $primary;
    width:100%;
    margin-right:5%;
}
.tituloMultimedia {
    padding: 1%;
    color: #2b2b2b;
    &:hover {
    color: $primary !important;
    }
}
.avisoPortada {
    width: 100%;
    height: auto;
    background: #b3b2b285;
    color: $secondary;
    padding: 1rem;
    margin-bottom: 1.5rem;

    h2 {
        font-weight: bold;
        font-size: 1.5rem;
    }

    span {
        font-size: 1.1rem;
    }
}

.login {
    /*width: 350px;*/

    .logout {
        color: $primary;
        font-size: 1.2rem;
        padding-right: 1rem;
    }

    .logoutIcon {
        width: 2rem;
        height: 2rem;
        color: $primary;
    }

    .logoutbtn {
        display: flex !important;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        text-decoration: none !important;
    }&:hover {
        text-decoration: none !important;
    }
}


.logoutbtn {
    color: $primary;
    text-decoration: none !important;
    margin-left: 1rem;

    &:hover {
        text-decoration: none !important;
        color: $primary;
    }
}

.PaginaLogin {
    width: 100%;
    text-align: center;

    .campoLogin {
        margin: 0.5REM !important;
        height: 2.5REM !important;
        width: 100%;
    }

    .btnLogin {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .logout {
        font-weight: bold;
        color: $primary;
        font-size: 1.2rem;
        padding-right: 1rem;
    }

    .logoutIcon {
        width: 2rem;
        height: 2rem;
        color: $primary;
    }

    .logoutbtn {
        display: flex !important;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        text-decoration: none !important;
    }

    &:hover {
        text-decoration: none !important;
    }

    .abonados {
        font-size:1.5rem;
    }
}

.loginSmall {
    width: 100%;
    text-align: center;
    margin-top: 1rem;

    .logout {
        font-weight: bold;
        color: $primary;
        font-size: 1.2rem;
        padding-right: 1rem;
    }

    .logoutIcon {
        width: 2rem;
        height: 2rem;
        color: $primary;
    }

    .logoutbtn {
        display: flex !important;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        text-decoration: none !important;
    }

    &:hover {
        text-decoration: none !important;
    }
}

.linkContrasena {
    font-size: 0.9rem;
    font-weight: normal;
}

.iconoRedes {
    color: white;
    margin-right: 0.3rem;
}

.paddingTitulo{
    padding:1rem;
}

.seccionInfografia {
    margin-top: .4rem;
    padding-left: 1rem;
}

.imgMultimedia {
    width: 100%;
    padding-left: 2%;
    padding-right: 6%;
}
.tituloContenidoMultimedia{
    font-size:22px;
    padding:3%;
}
.botonSlider {
    background: transparent; 
    color: white;
    border: none;
    margin: 0.7rem;
    /*margin-top: 1.5%;*/
    font-size: 17px;
    outline: 0;
}
.separadorProvincias {
    width: 100%;
    border-top: solid 4px $secondary;
    margin-top:1%;
    margin-bottom:1%;
}

.liMenuProvincias {
    padding-bottom: 0.4rem !important;
    padding-top: 0.4rem !important;
}

.archivoFotografico {
    background: $secondary;
    padding: 1.5rem;
    margin: 1rem;
    box-shadow: 2px 2px 5px 2px rgb(184, 184, 184);

    .fechaNoticia {
        color: $primary !important;
        margin-left: 1rem;
        font-size:15px!important;
    }

    .seccion {
        font-size: 1.2rem;
    }

    .tituloNoticiaPrivada {
        color: white !important;
    }

    .imgPrincipal {
        max-height: 15rem;
        object-fit: cover;
        width: 100%;
        object-position:top;
    }
}
.contenedorIconosFoto {
    text-align: center;
    background: white;
    box-shadow: 3px 3px 3px rgba(0,0,0,.1);
}
.biografia{
    margin-bottom:1.5rem;
}

.contenedorIconosTexto {
    text-align: center;
    background: $primary;
    box-shadow: 3px 3px 3px rgba(0,0,0,.1);
    padding: 0.5rem;
    display: inline-block;
    height: 100%;
    width: 15.5%;
    position: absolute;
    margin-left: 2.5%;
    /*margin-right: 2.5%;*/
    /*margin-right:100%;*/
}

.contenedorIconosTextoHorizontal {
    text-align: center;
    background: $primary;
    box-shadow: 3px 3px 3px rgba(0,0,0,.1);
    padding: 0.5rem;
    display: inline-block;

}

.iconoAcionHorizontal {
    width: 48% !important;
    height: 2rem !important;
    color: $secondary;
    /*margin-top: 100%;*/
 
    &:active {
        height: 1.6rem !important;
    }
}

.iconoLupa {
    width: 1.65rem !important;
    height: 1.65rem !important;
    color: $secondary;

    /*&:active {
        height: 1.6rem !important;
    }*/
}

.iconoAcionPrev {
    width: 1.5rem !important;
    height: 1.5rem !important;
    color: $primary;
    margin-left:2%;
    /*margin-top: 100%;*/
    &:active {
        height: 1.3rem !important;
    }
}

.galeriaFicha, .FotosVideos, .FotosVideosGrande {
    margin-top: 1.5rem;

    .slick-slide {
        margin-left: 1rem;
    }
}

.contenedorIconosTextoInferior {
    text-align: center;
    padding: 0.5rem;
    display: inline-block;
    width: 100%;
}

.iconoAcion {
    width: 50% !important;
    height: 50% !important;
    color: $secondary;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 40%;
    /*margin-top: 100%;*/
    &:active {
        width: 30% !important;
    }
}
.iconoAcionInferior {
    width: 1.7rem !important;
    height: 1.7rem !important;
    color: $secondary;
    margin-left: 2rem;
    margin-right: 2rem;
    /*margin-top: 100%;*/
    /*&:hover {
        width: 1.8rem !important;
        height: 1.8rem !important;
    }*/
    &:active {
        width: 1.6rem !important;
        height: 1.6rem !important;
    }
}
.PieFoto {
    font-size: 0.8rem;
    font-weight: normal;
    /*font-style: italic;*/
    color: white;
    padding:1.1rem;
}
.separadorLogin {
    border-bottom: solid 1px transparentize($secondary, .50);
    margin-top:1rem;
    margin-bottom:1rem;
}
.contactologin {
    color: $primary;
}
.menuPrivadoLink{
    color:white !important;
}
.tituloInterior {
    color: #4e4e4e;
    font-weight: bold;
    font-size: 20px;
    /*margin-left: 3rem;*/
}
.subtitulo {
    color: black;
    font-size: 1rem;
    margin-top: 0.5rem;
}
.nav-item {
    /*font-weight: bold;*/
    margin-right: 4rem;
}
*,
*::before,
*::after {
    box-sizing: border-box; // 1
    outline: 0 !important;
}

.despNoticias {
    display: inline-block; 
    width: auto;
    /*width: 47%;*/
    margin-left: 1%;
    border-color: transparentize($primary, .10);
    /*margin-bottom: 3rem;*/
}

.imagenNoticia {
    height: 23rem;
    max-width: 100%;
    display: block;
    margin: auto;
}


.listaProvincias {
    list-style: none;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;

    .provinciaMenu {
        width: 2rem;
        display: inline;
        margin: 2rem;
    }
}

.listaPie {
    list-style: none;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    width:100%;
}

.imagenDelMes{
    height:15rem!important ;
    object-fit:cover;

}

.chBuscador {
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
}

.pieEnlace {
    color: $secondary;
    margin-left: 1rem;
    margin-right: 1rem;

    &:hover {
        text-decoration: none;
        color: $primary;
    }
}

article{
    margin-bottom:1rem;
}

.marcoPaginacion {
    padding: 1em 1em 0 0;
    font-size: 1.2em;
    text-align: center;
    width:100%;
}

.marcoPaginacion {

    a {
        color: $primary;
        text-decoration: none;
        padding: .3em .6em;
        margin-top: 0.1em;
        display: inline-block;
    }
}
.barrita:nth-of-type(1) {
    display: none;
}

.card-body {
    padding:0 !important ;
    padding-top:0.75rem !important ;
}


a.seleccionada {
    background: #E9E9E9;
}



.imgMosaicoPequeña{
    width:100%;
    height:33.33%;
    object-fit:cover;
}

.imgMosaicoMediana {
    width: 33.33%;
    height: 40%;
    object-fit: cover;
    display: inline-block;
}
.imgMosaicoGrande {
    width: 37%;
    height: 60%;
    object-fit: cover;
    display: inline-block;
    .jwplayer{
        height:100% !important ;
    }
}
@media (max-width: 1400px) {
    #menu2 {
        .nav-item {
            margin-right: 3rem !important;
        }
    }

    .nav-item {
        margin-right: 1.3rem !important;
    }

}
@media (max-width: 1100px) {

    .nav-item {
        margin-right: 1rem !important;
    }

    #menu1 {
        font-size: 1.15rem;
    }

    .navbar {
        padding: .5rem 0rem;
    }
}
@media (max-width: 990px) {

    #videoFull {
        display:block!important ;
    }

    #mosaico {
       
         display: none!important ;

    }

}

@media (max-width: 1000px) {
    #menu1, #menu2 {
        display: none;
    }
    .contenedorPrincipal {
        margin-top: 10rem !important;
    }
    .textoGrande {
        font-size: 2.5rem;
    }

    .textoComercial2 {
        font-size: 1.2rem;
        line-height: 1.6rem;
        padding-top: 0 !important;
    }
    .archivoFotografico {
        margin: 0 !important;
        margin-bottom: 1rem !important;
        margin-top: 1rem !important;
    }
}


@media (max-width: 768px) {

    .login{
        margin-top:3rem;
        font-size:1.3rem;
    }
    .campoLogin {
        height: auto;
        width: 20rem;
    }
    .textoComercial1 {
        padding-left: 2rem;
    }
}


    @media (max-width: 600px) {

        .imagenNoticia {
            height: 15rem;
        } 
        
        #btnLogout {
            display: none;
        }
        
        .avisoPortada {
            h2 {
                font-weight: bold;
                font-size: 1.3rem;
            }

            span {
                font-size: 1rem;
            }
        }


        .listaClientes {
            padding: 0 !important;
        }

        .cuadroImagen {
            margin-top: 0px !important;
        }

        .textoGrande {
            font-size: 2.3rem;
        }

        .textoComercial2 {
            font-size: 1rem;
            line-height: 1.5rem;
            padding-top: 0 !important;
        }

        .contenedorPrincipal {
            margin-top: 10rem !important;
        }

        h1 {
            font-size: 2rem;
        }

        .wrapper > h4 {
            font-size: 1rem !important;
        }

        /*.tituloReportajes {
        margin: 6%;
    }*/
    }

.parpadea {
    animation-name: parpadeo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes parpadeo {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes parpadeo {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}


